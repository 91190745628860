<template>
  <div id="partner_section" class="col-md-12 mt-3 mb-3" v-if="partners.length!=0">
    <b-card body-class="pl-0 pt-0 pr-0 table-responsive">
      <b-card-header style="display: block;" class="border-bottom-0">
        <div class="profile-body">
          <div class="mem-header">
            <div class="header-title">
              <h4>Partner</h4>
            </div>
          </div>
        </div>
      </b-card-header>

      <b-card-body>
        <div class="row justify-content-center">
          <div class="col-md-3 p-4" v-for="(partner, index) in partners" :key="index">
            <a href="javascript:void(0)" @click="partnerPage(partner.id)">
              <b-card :img-src="basePath+'images/partners/'+partner.logo" no-body border-variant="secondary" class="shadow-sm">
                <b-card-footer class="font-weight-bold" style="background-color: #faf4f4 !important; color: #000">{{ partner.name }}</b-card-footer>
              </b-card>
            </a>
          </div>
        </div>
      </b-card-body>

    </b-card>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      partners: [],
    };
  },
  methods: {
    getPartners() {
      axios
          .get(this.basePath + "api/partners/getPartners", { headers: this.memberHeaders })
          .then((response) => {
            if (response.data.status === "success") {
              this.partners = response.data.data;
            } else {
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },
    partnerPage(id) {
      this.$emit("partner-clicked", id);
    },
  },
  mounted() {
    this.getPartners();
  },
}
</script>